import Vue from 'vue'
import vuetify from './plugins/vuetify'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueTelInputVuetify from 'vue-tel-input-vuetify'
import VueMeta from 'vue-meta'
import OtpInput from "@bachdgvn/vue-otp-input"

// Disable shitty prod message

Vue.config.productionTip = false


// Register global libraries & components

Vue.use(VueAxios, axios)
Vue.use(VueTelInputVuetify, { vuetify });
Vue.use(VueMeta, { refreshOnceOnNavigation: true })
Vue.component("v-otp-input", OtpInput)


// Register global directives

Vue.directive(
  'bubble',
  (el, binding, vnode) => {
    Object.keys(binding.modifiers).forEach(event => {
      // Bubble events of Vue components
      if (vnode.componentInstance) {
        vnode.componentInstance.$on(event, (...args) => {
          vnode.context.$emit(event, ...args)
        })

        // Bubble events of native DOM elements
      } else {
        el.addEventListener(event, payload => {
          vnode.context.$emit(event, payload)
        })
      }
    }
    )
  }
)

/*
 * Directive that focusses a from input
*/
Vue.directive(
  'focus',
  {
    // When the bound element is inserted into the DOM...
    inserted: function (el) {
      el.focus()
    }
  }
)

// Instantiate Vue

new Vue(
  {
    vuetify,
    router,
    render: h => h(App)
  }
).$mount('#app')