
<template>
  <v-app>
    <div id="app" class="app">

      <v-banner
        class="banner"
        color="primary"
        elevation="3"
      >
        {{ HEADER_TEXT }}
      </v-banner>

      <img
        class="logo"
        :src="LOGO_URL"
      />

      <router-view
        v-if="hasState"
        :state="state"
        :error="error"
        @busy="busy = true"
        @done="busy = false"
        @response="parseResponse"
        @change="error=false"
      />

      <v-overlay
         color="background"
         :value="busy"
      >
        <v-progress-circular
          indeterminate
          color="secondary"
        >
        </v-progress-circular>
      </v-overlay>

    </div>
  </v-app>
</template>

<!-- ----------------------------------------------------------------------- -->

<script>

  import api from '@/mixins/api.mixin'
  import util from '@/mixins/util.mixin'
  import state from '@/mixins/state.mixin'

  export default {

    // -------------------------- Metadata

    name:"app",
    mixins: [api, state.mixin, util],

    metaInfo() {
      return {
        title:
          this.getValue(this, '/state/client/displayName') || 'Authenticate'
      }
    },

    // -------------------------- Properties

    data: function() {
      return {
        busy: false,
      }
    },

    computed: {

      HEADER_TEXT() {
        return 'South African Covid-19 Vaccination Booking System'
      },

      LOGO_URL() {
        return this.getValue(this, '/state/client/logo')
      }
    },
  }


</script>

<!-- ----------------------------------------------------------------------- -->

<style lang="scss">

  .app {

    text-align: center;
    padding-bottom: 2em;

    .banner{
      color: #333;
      color: #fff;
    }

    .logo{
      margin: 3em 0;
    }

    h1{
      margin-bottom: 2em;
    }
  }

</style>
