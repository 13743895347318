
<template>
  <div class="verify-view">
    <v-row align="center" no-gutters>

      <v-col cols="12">

        <p class="prefix">{{ PREFIX_TEXT }}</p>

        <v-text-field
          class="mobile-field"
          disabled
          :value="mobile"
        />

        <p class="suffix">{{ SUFFIX_TEXT }}</p>

        <!-- Wrong number button -->

        <v-btn
          elevation="2"
          class="button"
          color="warning"
          @click="onWrong"
        >
          {{ WRONG_TEXT }}
          <v-icon right>{{ mdiWrongNumber }}</v-icon>
        </v-btn>

        <!-- Send button -->

        <v-btn
          elevation="2"
          class="button"
          color="primary"
          @click="onSend"
        >
          {{ BUTTON_TEXT }}
          <v-icon right>{{ mdiEmailSendOutline }}</v-icon>
        </v-btn>

      </v-col>

    </v-row>

  </div>
</template>

<!-- ----------------------------------------------------------------------- -->

<script>

  import { mdiEmailSendOutline, mdiCellphoneOff } from '@mdi/js'
  import api from '@/mixins/api.mixin'
  import util from '@/mixins/util.mixin'

  export default {

    // -------------------------- Metadata

    name: 'VerifyView',
    mixins: [api.mixin, util],
    props: ['state'],

    // -------------------------- Properties

    data: function(){
      return {
        completed: false,
      }
    },

    computed: {

      mdiEmailSendOutline() { return mdiEmailSendOutline },
      mdiWrongNumber() { return mdiCellphoneOff },

      mobile(){
        return this.getValue(
          this.state, '/person/mobile'
        )
      },

      PREFIX_TEXT() { return 'You registerred with mobile number:' },
      SUFFIX_TEXT() { return 'We will send your One Time Pin (OTP) to this mobile number.' },
      WRONG_TEXT() { return 'Not my number' },
      BUTTON_TEXT() { return 'Send' },
    },

    // -------------------------- Methods

    methods: {

      isVerifyDisabled(){
        return (
          this.error || !this.complete
        )
      },

      async onSend() {
        let response = await this.apiPost(
          {
            requestOtp: true,
            personId: this.getValue(this, '/state/person/id'),
            personIdType: this.getValue(this, '/state/person/type')
          }
        )
        this.$emit('response', response)
      },

      onWrong(){
        this.$emit(
          'response',
          {
            error: {
              message: 'Please update registration information at:',
              link: 'https://vaccine.enroll.health.gov.za/#/'
            }
          }
        )
      }
    }
  }

</script>

<!-- ----------------------------------------------------------------------- -->

<style lang="scss">

  .verify-view{

    .row{
      margin: auto;
      max-width: 750px;
    }

    .col{

      .prefix, .suffix{
        margin: 2em 0;
      }

      .mobile-field{
        max-width: 400px;
        margin: 0 auto;
        padding-top: 0;
        height: 2.5em;

        .v-text-field__slot input{
          text-align: center;
        }
      }

      .button{
        margin: 0 0.5em;
      }
    }
  }

</style>
