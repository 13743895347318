
<template>
  <div class="id-load">
    <v-icon disabled size="200px">{{ mdiCloudDownload }}</v-icon>
  </div>
</template>

<!-- ----------------------------------------------------------------------- -->

<script>

  import { mdiCloudDownload } from '@mdi/js'

  export default {

    // -------------------------- Properties

    props: [ 'state' ],

    // -------------------------- Metadata

    name: "LoadingView",

    // -------------------------- Properties

    computed: {
      mdiCloudDownload() { return mdiCloudDownload },
    }
  }

</script>

<!-- ----------------------------------------------------------------------- -->

<style lang="scss">

  .id-load{

    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    max-width: 750px;
    min-height: 50vh;
    margin: auto;

    div {
      padding: 1em;
    }
  }

</style>
