
<template>
  <div class="id-view">

    <v-row
      align="center" no-gutters>

      <v-col cols="12">

        <p class="prefix">{{ INTRO_TEXT }}</p>

        <v-select
          v-if="showIdType"
          v-model="idType"
          class="id-field"
          :items="ID_TYPES"
          :label="ID_TYPE_PLACEHOLDER"
        />

        <v-text-field
          v-if="showId"
          v-model="idNumber"
          class="id-field"
          ref="idField"
          :error-messages="errorMessage()"
          :label="ID_LABEL"
          :placeholder="ID_PLACEHOLDER"
          :rules="[ ruleRequired ]"
          @keydown="$emit('change')"
        />

        <!-- Search button -->

        <v-btn
          class="id-search"
          elevation="2"
          color="primary"
          :disabled="isSearchDisabled"
          @click="onSearch"
        >
          {{ BUTTON_TEXT }}
          <v-icon right>{{ mdiMagnify }}</v-icon>
        </v-btn>

      </v-col>

    </v-row>

  </div>
</template>

<!-- ----------------------------------------------------------------------- -->

<script>

  import { mdiMagnify } from '@mdi/js'
  import api from '@/mixins/api.mixin'
  import state from '@/mixins/state.mixin'
  import util from '@/mixins/util.mixin'

  export default {

    // -------------------------- Metadata

    name: 'IDView',
    mixins: [api.mixin, util],

    // -------------------------- Properties

    props: [ 'state', 'error' ],

    data: function() {
      return {
        idNumber: '',
        idType: ''
      }
    },

    computed: {

      mdiMagnify() { return mdiMagnify },

      showContent(){
        return this.state.status != state.UNKNOWN
      },

      showId(){

        let type = this.getValue(this.$route.query, '/type')

        return (
          !type || (type && !!this.idType)
        )
      },

      showIdType(){
        return !!this.getValue(this.$route.query, '/type')
      },

      isSearchDisabled(){
        return (
          this.getValue(this.$route.query, '/type') && this.idType == false
          || !this.idNumber
          || !!this.error
        )
      },

      INTRO_TEXT() {
        return [
          'This is the official South African booking system.',
          'for COVID-19 vaccinations. ',
          'Please note that booking is voluntary, ',
          'if you do not place a booking you will be automatically scheduled.'
        ].join('')
      },

      ID_LABEL() {

        let
            typeLabel = this.ID_TYPES.find(
              (element) => this.idType ? element.value == this.idType : false
            ),
            type = typeLabel
              ? typeLabel.text.toLowerCase()
              : 'identification number'

        return `Please enter the ${type} used to register`
      },

      ID_PLACEHOLDER() {

        let
            typeLabel = this.ID_TYPES.find(
              (element) => this.idType ? element.value == this.idType : false
            ),
            type = typeLabel
              ? typeLabel.text
              : 'identification number'

        return type || 'ID/Passport/Refugee number'
      },

      REQUIRED_TEXT() { return 'ID/Passport/Refugee number is required' },

      ID_TYPES() {
        return [
          { text: 'ID number', value: 'id' },
          { text: 'Passport number', value: 'passport' },
          { text: 'Refugee number', value: 'refugeenumber' },
        ]
      },

      ID_TYPE_PLACEHOLDER() {
        return 'Please select identification type used to register'
      },

      BUTTON_TEXT() { return 'Search' }
    },

    // -------------------------- Methods

    methods: {

      ruleRequired(value) {
        return value
          ? true
          : this.REQUIRED_TEXT
      },

      async onSearch(){

        this.$refs.idField.validate(true)

        if (this.$refs.idField.valid) {

          let response = await this.apiPost(
              {
                personId: this.idNumber,
                personIdType: this.idType ? this.idType : null,
              }
            )

          this.$emit('response', response)
        }
      }
    },

    // -------------------------- Lyfecycle Hooks

    mounted() {
      this.$refs.idField.focus()
    }
  }

</script>

<!-- ----------------------------------------------------------------------- -->

<style lang="scss">

  .id-view{

    .row{
      margin: auto;
      max-width: 750px;
    }

    .col{

      .prefix{
        margin: 2em 0 3em;
      }

      .id-field{
        max-width: 400px;
        margin: 1em auto;
        text-align: left;
      }

      .id-search{
        margin: 2em;
      }
    }
  }

</style>