
import axios from 'axios'
import sha256 from 'crypto-js/sha256'
import Base64 from 'crypto-js/enc-base64'
import utf8 from 'utf8'

let api = {

  mixin: {

    // -------------------------- Properties

    props: ['headers'],

    computed: {

      API_REQUEST_ID() {
        return (
          this.$route.params.requestId ||
          this.state.requestId
        )
      },

      API_QUERY_ID() {
        return typeof this.$route.query.id != 'undefined'
          ? this.$route.query.id
          : false
      },

      API_URL() {
        return  [
          process.env.VUE_APP_API_HOST
            ? process.env.VUE_APP_API_HOST
            : '',
          'api',
          'public',
          '1',
          'persons',
          'authentication-requests',
          this.API_REQUEST_ID
        ].join('/')
      }
    },

    // -------------------------- Methods

    methods: {

      async apiInit(){

        if (this.API_REQUEST_ID) {
          try{

            api.API_HEADERS = await this.apiHeaders()
            if (!api.API_HEADERS) return api.TIMESTAMP_ERROR

            const response = await axios.get(this.API_URL, api.API_HEADERS)

            return this.getValue(response, '/data')

          } catch (error){
            return {
              error: {
                code: this.getValue(error, '/response/status'),
                message: this.getValue(error, '/response/message'),
              }
            }
          }
        }

        return api.REQUEST_ID_ERROR
      },

      async apiPost(payload){
        if (this.API_REQUEST_ID) {

          this.$emit('busy')

          try{

            const
              response = await axios.post(
              this.API_URL,
              payload,
              api.API_HEADERS
            )

            this.$emit('done')

            return this.getValue(response, '/data')

          } catch(error) {

            this.$emit('done')

            let pageLevelError = this.getValue(error, '/response/data')

            return pageLevelError
              ? pageLevelError
              : {
                error: {
                  code: this.getValue(error, '/response/status'),
                  message: 'An application authorisation error has occurred'
                }
              }
          }
        }

        return {
          error: {
            code: 500,
            message: 'Something went wrong, please try again later'
          }
        }
      },

      // ------------- Utility

      async apiHeaders() {

        const
          response = await axios.get(
            [
              process.env.VUE_APP_API_HOST
                ? process.env.VUE_APP_API_HOST
                : '',
              `api/public/1/now`,
            ].join('/'),
            { 'headers': { 'Accept': 'application/json' } }
          ),
          timestamp = this.getValue(response, '/data/data/now'),
          signature = utf8.decode(
            Base64.stringify(
              sha256(
                utf8.encode(
                  `${api.CLIENT}${timestamp}${api.SECRET}`
                )
              )
            )
          )

        return {
          'headers': {
            'Accept': 'application/json',
            'X-EVDS-Key': 'auth-web-ui',
            'X-EVDS-Tstamp': timestamp,
            'X-EVDS-Signature': signature
          }
        }
      }
    }
  },

  HOST: process.env.VUE_APP_API_HOST,
  CLIENT: process.env.VUE_APP_API_CLIENT,
  SECRET: process.env.VUE_APP_API_SECRET,

  TIMESTAMP_ERROR: {
    error: {
      message: 'A timestamp could not be retrieved from the server, please try again later'
    }
  },
  REQUEST_ID_ERROR: {
    error: {
      code: 'EVDS-001',
      message: 'No authentication request ID found'
    }
  },
  UNEXPECTED_ERROR: {
    error: {
      code: null,
      message: 'An unexpected error has occurred, please try again later'
    }
  },
  SERVER_ERROR: {
    error: {
      message: ''
    }
  }
}

export default api