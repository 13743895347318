
import api from './api.mixin'
import util from './util.mixin'


let state = {

  mixin: {

    mixins: [api.mixin, util],

    data: function(){
      return {

        state: {

          status: state.UNKNOWN,
          error: false,

          requestId: false,

          person: {
            id: false,
            type: false,
            mobile: false
          },

          client: {
            displayName: 'Athenticate',
            logoUrl: require('@/assets/logo.png')
          },

          retries: 0
        },

        error: false,
      }
    },

    methods: {

      hasState(){ return this.state.status != state.UNKNOWN },

      parseResponse(response){
        response.error
          ? this.setError(response.error)
          : this.setState(response.data)
      },

      setError(error) {

        this.state.status = state.STATE_ERROR

        console.log(error)

        const
          httpLevelError = !error.errors,
          firstError = this.getValue(error, '/errors/0'),
          pageLevelError = firstError
            ? [ 3, 5, 7 ].find(
              (errorNo) =>
                firstError.reason == `EVDS-${('000' + errorNo).slice(-3)}`
            )
            : false,
          appLevelError = !pageLevelError

        switch (true) {

          case httpLevelError:
            this.error = error
            this.errorPage()
          break

          case appLevelError:
            this.error = {
              code: firstError.reason,
              message: firstError.message
            }
            this.errorPage()
          break

          case !!pageLevelError:
            this.state.retries ++
            if (this.state.retries > 2) {
              firstError.location
                ? this.redirect(firstError.location)
                : this.errorPage()
            }
            this.error = firstError
          break

          default:
            this.state.status = state.STATE_ERROR
            this.error = firstError
        }
      },

      setState(response) {

        let
          nextUrl =
            this.getValue(response, '/authenticated/nextUrl') ||
            this.getValue(response, '/expired/nextUrl') ||
            this.getValue(response, '/failed/nextUrl') // TODO: awaiting approval to remove

        if (nextUrl) {
          this.redirect(nextUrl)
          return
        }

        this.state = {
          status: state.STATE_OK,
          requestId: this.getStateRequestId(),
          client: this.getStateClient(response),
          person: this.getStatePerson(response),
          from: this.$route.name,
          retries: this.state.retries
        }

        switch (response.type) {
          case 'Requires_Id': this.navigate('ID'); break
          case 'Requires_Id_Type': this.navigate('ID', { type: 'ask' }); break
          case 'Requires_Otp': this.navigate('OTP'); break
          case 'Requires_Otp_Request': this.navigate('Verify'); break
        }
      },

      getStateRequestId(){
        return (
          this.$route.params.requestId ||
          this.getValue(this, '/state/requestId')
        )
      },

      getStateClient(response){
        return (
          this.getValue(response, '/client') ||
          this.getValue(this, '/state/client')
        )
      },

      getStatePerson(response){
        let
          id = null,
          type = null

        const
          requiresType = this.getValue(response, '/requiresIdType'),
          requiresOtp = this.getValue(
            response,
            '/requiresOtp/personIdentifier'
          ),
          requiresOtpRequest = this.getValue(
            response,
            '/requiresOtpRequest/personIdentifier'
          ),
          mobile = this.getValue(
            response,
            '/requiresOtpRequest/obfuscatedMobileNumber'
          )

        switch (true) {
          case !!requiresType: id = requiresType.personIdentifierId; break
          case !!requiresOtp: ({ id, type } = requiresOtp); break
          case !!requiresOtpRequest: ({ id, type } = requiresOtpRequest); break
        }

        return id
          ? { id, type, mobile }
          : this.getValue(this, '/state/person')
      }
    },

    // -------------------------- Lyfecycle Hooks

    async mounted() {
      this.busy = true
      this.parseResponse(await this.apiInit())
      this.busy = false
    }
  },

  STATE_UNKNOWN: 0,
  STATE_OK: 1,
  STATE_ERROR: 2
}

export default state