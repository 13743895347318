
<template>
  <div class="otp-view">
    <v-row align="center" no-gutters>

      <v-col cols="12">

        <p class="prefix">{{ PREFIX_TEXT }}</p>

        <v-input
          class="otp"
          :error-messages="errorMessage()"
        >

          <v-otp-input
            ref="otpInput"
            input-classes="otp-input"
            separator="-"
            :num-inputs="4"
            :should-auto-focus="true"
            :is-input-num="true"
            @on-change="onChange"
            @on-complete="onComplete"
          />
        </v-input>

        <p class="suffix">{{ SUFFIX_TEXT }}</p>

        <!-- Resend button -->

        <v-btn
          elevation="2"
          class="button"
          color="warning"
          @click="onResend"
        >
          {{ RESEND_TEXT }}
          <v-icon right>{{ mdiEmailSyncOutline }}</v-icon>
        </v-btn>

        <!-- Send button -->

        <v-btn
          elevation="2"
          class="button"
          color="primary"
          :disabled="sendDisabled()"
          @click="onVerify"
        >
          {{ BUTTON_TEXT }}
          <v-icon right>{{ mdiLoginVariant }}</v-icon>
        </v-btn>

      </v-col>

    </v-row>

  </div>
</template>

<!-- ----------------------------------------------------------------------- -->

<script>

  import { mdiLoginVariant, mdiEmailSyncOutline } from '@mdi/js'
  import api from '@/mixins/api.mixin'
  import util from '@/mixins/util.mixin'

  export default {

    // -------------------------- Metadata

    name: 'OTPView',
    mixins: [api.mixin, util],
    props: ['state', 'error'],

    // -------------------------- Properties

    data: function() {
      return {
        otp: '',
        complete: false
      }
    },

    computed: {

      mdiLoginVariant() { return mdiLoginVariant },
      mdiEmailSyncOutline() { return mdiEmailSyncOutline },

      PREFIX_TEXT() { return 'Please enter your One Time Pin (OTP):' },
      SUFFIX_TEXT() {
        return 'If you have not received your OTP, we can resend it.'
      },
      BUTTON_TEXT() { return 'Verify OTP' },
      RESEND_TEXT() { return 'Resend OTP' }
    },

    // -------------------------- Methods

    methods: {

      sendDisabled(){
        return !!this.error || !this.complete
      },

      async onResend(){
        let response = await this.apiPost( {
          requestOtp: true,
          personId: this.getValue(this.state, '/person/id'),
          personIdType: this.getValue(this.state, '/person/idType'),
        } )
        this.$emit('response', response)
      },

      async onVerify(){
        let response = await this.apiPost( {
          otp: this.otp,
          personId: this.getValue(this.state, '/person/id'),
          personIdType: this.getValue(this.state, '/person/idType'),
        } )
        this.$emit('response', response)
      },

      onChange(){
        this.$emit('change')
        this.complete = false
      },

      onComplete(value){
        this.otp = value
        this.complete = true
      }
    },

    mounted(){
      this.$refs.otpInput.$children[0].$refs.input.focus()
    }
  }

</script>

<!-- ----------------------------------------------------------------------- -->

<style lang="scss">

  .otp-view{

    .row{
      margin: auto;
      max-width: 750px;
    }

    .col{

      .prefix, .suffix{
        margin: 2em 0;
      }

      .otp{

        .v-input__slot,
        .v-messages{
          display: flex;
          justify-content: center;
        }

        .otp-input {
          width: 40px;
          height: 40px;
          padding: 5px;
          margin: 0 10px;
          font-size: 20px;
          border-radius: 4px;
          border: 1px solid rgba(0, 0, 0, 0.3);
          text-align: center;
          &.error {
            border: 1px solid red !important;
          }
        }
        .otp-input::-webkit-inner-spin-button,
        .otp-input::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }

      .button{
        margin: 0 0.5em;
      }
    }
  }

</style>
