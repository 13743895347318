import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify, { options: { customProperties: true }})

/* Define theme colours */

const

  VUETIFY_OPTIONS = {

    theme: {
      treeShake: true,
      themes: {
        light: {
          primary: '#62a81f',
          secondary: '#8ebe5e',
          accent: '#cddc39',
          error: '#ff5722',
          warning: '#ff9800',
          info: '#2196f3',
          success: '#009688',
          background: '#fff',
        }
      }
    },

    icons: {
      iconfont: 'mdiSvg'
    }
  }


export default new Vuetify(VUETIFY_OPTIONS)