
<template>
  <div class="id-error">
    <v-icon large>{{ mdiHeadQuestionOutline }}</v-icon>
    <div class="prefix">{{ INTRO_TEXT }}</div>
    <p v-if=error.link>
      <a :href="error.link">
        {{ error.link }}
      </a>
    </p>
  </div>
</template>

<!-- ----------------------------------------------------------------------- -->

<script>

  import { mdiHeadQuestionOutline } from '@mdi/js'
  import api from '@/mixins/api.mixin'

  export default {

    // -------------------------- Metadata

    name:"ErrorView",
    mixins: [ api ],
    props: [ 'state', 'error' ],

    // -------------------------- Properties

    computed: {

      mdiHeadQuestionOutline() { return mdiHeadQuestionOutline },

      INTRO_TEXT() {
        return this.error
          ? [this.error.code || this.error.reason, this.error.message]
            .filter(
              (value) => !!value
            ).join(': ')
          : 'No authentication request found'
      },
    }
  }

</script>

<!-- ----------------------------------------------------------------------- -->

<style lang="scss">

  .id-error{

    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    max-width: 750px;
    min-height: 50vh;
    margin: auto;

    div {
      padding: 1em;
    }
  }

</style>
