import jsonpointer from 'jsonpointer'

export default {
  methods: {

    getValue(model, path) {
      try {
        return jsonpointer.get(model, path)
      } catch (error) {
        return false
      }
    },

    redirect(url) {
      window.location.href = url
    },

    async errorPage(){
      try {
        await this.$router.replace({ name: 'Error' })
      } catch (error) {
        console.log('Routing to same page')
      }
    },

    errorMessage() {
      return this.error
        ? this.getValue(this.error, '/message')
        : null

    },

    async navigate(name, query) {
      try {
        await this.$router.replace(
          {
            name: name || 'Error',
            query: {...this.$router.query, ...query},
            params: { requestId: this.state.requestId },
          }
        )
      } catch (error) {
        console.log('Routing to same page')
      }
    }
  }
}