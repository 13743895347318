import Vue from 'vue'
import VueRouter from 'vue-router'

import LoadView from '../views/LoadView.vue'
import IDView from '../views/IDView.vue'
import VerifyView from '../views/VerifyView.vue'
import OTPView from '../views/OTPView.vue'
import ErrorView from '../views/ErrorView.vue'


Vue.use(VueRouter)


const router = new VueRouter(
  {
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [

      {
        path: '/:requestId/error',
        name: 'Error',
        component: ErrorView
      },

      {
        path: '/:requestId/verify',
        name: 'Verify',
        component: VerifyView
      },

      {
        path: '/:requestId/otp',
        name: 'OTP',
        component: OTPView
      },

      {
        path: '/:requestId/id',
        name: 'ID',
        component: IDView
      },

      {
        path: '/:requestId',
        name: 'Load',
        component: LoadView
      }
    ]
  }
)

export default router
